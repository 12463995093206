import { Event } from "../../../../service/event/event.model";

export interface EventShareModalProps {
  pageId: string;
  visible: boolean;
  event?: Event;
  onCloseRequest: Function;
}

export enum EventUrl {
  Preview = "Preview",
  Live = "Live",
}
