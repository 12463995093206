import "./app.scss";
import React from "react";
import ContextProvider from "./context/context.provider";
import { Root } from "./pages/pages.index";

const App = (): JSX.Element => {
  return (
    <ContextProvider>
      <Root />
    </ContextProvider>
  );
};

export default App;
