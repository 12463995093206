import React, { createContext, PropsWithChildren, useState, useRef } from "react";
import { EventContextState as EventContextStateModel } from "./event.definition";
import EventService, { Event } from "../../service/event/event.service";
import { ApiResponse } from "../../service/api/api.definition";
import { isEmpty, NotificationService } from "@q4/nimbus-ui";

const EventContext = createContext<Partial<EventContextState>>({});

export const EventProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const [eventsByClient, setEventsByClient] = useState<Event[]>();
  const [eventById, setEventById] = useState<Event>();
  const eventService = useRef(new EventService());
  const notificationService = useRef(new NotificationService());

  function getById(id: string): Promise<boolean> {
    if (!isEmpty(eventsByClient)) {
      const event = eventsByClient.find((x): boolean => x.id === id);

      if (!isEmpty(event)) {
        setEventById(event);
        return Promise.resolve(true);
      }
    }

    return eventService.current.getById(id).then((response): boolean => {
      const event = handleResponse(response);
      setEventById(event);
      return !!response?.success;
    });
  }

  function getByClient(id: string, forceFetch = false): Promise<boolean> {
    if (isEmpty(id)) return Promise.resolve(false);

    if (!forceFetch && !isEmpty(eventsByClient) && eventsByClient[0]?.organizationId === id) return Promise.resolve(true);

    return eventService.current.getByClient(id).then((response): boolean => {
      const events = handleResponse(response) ?? [];
      setEventsByClient(events);
      return !!response?.success;
    });
  }

  function post(data: Event): Promise<boolean> {
    return eventService.current
      .addEvent(data)
      .then((response): boolean => {
        if (!response?.success) {
          throw new Error("Failed to add the event.");
        }

        notificationService.current.success("The event has been added successfully.");

        return !!response?.success;
      })
      .catch((error): boolean => {
        notificationService.current.error(error.message);
        return false;
      });
  }

  function put(id: string, data: Event): Promise<boolean> {
    return eventService.current
      .editEvent(id, data)
      .then((response): boolean => {
        if (!response?.success) {
          throw new Error("Failed to update the event.");
        }

        // update eventsByClient if the event is part of the collection
        if (!isEmpty(eventsByClient) && !isEmpty(response?.data)) {
          setEventsByClient(eventsByClient.map((event): Event => (event.id === id ? { ...event, ...response.data } : event)));
        }

        setEventById(response.data);

        notificationService.current.success("The event has been updated successfully.");

        return !!response?.success;
      })
      .catch((error): boolean => {
        notificationService.current.error(error.message);
        return false;
      });
  }

  /**
   * update the presentation on the event
   */
  function putPresentation(id: string, presentation: File): Promise<boolean> {
    return eventService.current
      .putPresentation(id, presentation)
      .then((response): boolean => {
        if (!response?.success) {
          throw new Error("Failed to upload the presentation.");
        }

        const { presentationSignedUrl, previewToken } = response.data;

        // update eventsByClient if the event is part of the collection
        if (!isEmpty(eventsByClient)) {
          setEventsByClient(eventsByClient.map((event): Event => (event.id === id ? { ...event, presentationSignedUrl, previewToken } : event)));
        }

        setEventById({
          ...eventById,
          presentationSignedUrl,
          previewToken,
        });

        notificationService.current.success("The presentation was uploaded successfully.");

        return !!response?.success;
      })
      .catch((error): boolean => {
        notificationService.current.error(error.message);
        return false;
      });
  }

  function handleResponse<T>(response: ApiResponse<T>): T {
    if (!response.success) {
      notificationService.current.error("Failed to load event.");
      return null;
    }
    return response.data;
  }

  return (
    <EventContext.Provider
      value={{
        getById,
        getByClient,
        post,
        put,
        putPresentation,
        eventsByClient,
        eventById,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventContext;

export type EventContextState = EventContextStateModel;
