import "./logout.page.scss";
import React, { memo, useEffect, useRef } from "react";
import { ButtonTheme, ButtonSize, PlaceholderContent, PlaceholderContentTheme, LayoutHeight } from "@q4/nimbus-ui";
import { LogoutProps } from "./logout.definition";
import UserContext from "../../context/user/user.context";
import contextSubscribe from "../../context/context.subscribe";
import AuthService from "../../service/auth/auth.service";
import { PageId } from "../pages.definition";

const Logout = (props: LogoutProps): JSX.Element => {
  const authService = useRef(new AuthService());
  const { user } = props;

  useEffect(logout, []);

  function logout(): void {
    authService.current.logOut().then((): void => {
      user.getCurrentUser();
    });
  }

  return (
    <div id={PageId.Logout} className="logout">
      <PlaceholderContent
        icon="q4i-logo"
        theme={PlaceholderContentTheme.Dark}
        title="You've been logged out"
        subtitle="Enjoy the rest of your day!"
        actions={[
          {
            label: "Log back in",
            theme: ButtonTheme.Citrus,
            size: ButtonSize.Large,
            linkTo: "/login",
          },
        ]}
        layoutProps={{
          height: LayoutHeight.Full,
        }}
      />
    </div>
  );
};

export default contextSubscribe<LogoutProps>([{ context: UserContext, mapToProps: "user" }], memo(Logout));
