export enum PageId {
  Auth = "Auth",
  ClientList = "ClientList",
  ClientDetails = "ClientDetails",
  EventDetails = "EventDetails",
  Login = "Login",
  Logout = "Logout",
  PublicViewer = "PublicViewer",
  Unauthorised = "Unauthorised",
}
