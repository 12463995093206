import { RouteComponentProps } from "react-router-dom";
import { EventContextState } from "../../../context/event/event.definition";
import { ClientContextState } from "../../../context/client/client.definition";

export const ColumnDefinitions = [
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "startDate",
    headerName: "DATE & TIME",
  },
  {
    field: "status",
    headerName: "STATUS",
  },
];

export enum EventStatusLabel {
  Archived = "Archived",
  Upcoming = "Upcoming",
  Live = "Live",
}

export interface ClientMatchParams {
  clientId: string;
}

export interface ClientDetailsProps extends RouteComponentProps<ClientMatchParams> {
  event: EventContextState;
  client: ClientContextState;
}

const PageId = "ClientDetails";

export const IdDefinitions = {
  Page: PageId,
  Banner: `${PageId}Banner`,
  Toolbar: `${PageId}ToolbarTitle`,
  Search: `${PageId}Search`,
  Grid: `${PageId}Grid`,
};
