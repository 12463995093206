import { AppConfig } from "../../models/appConfig.model";

export class ConfigService {
  private appConfig: AppConfig;

  async get(): Promise<AppConfig> {
    if (this.appConfig) {
      return Promise.resolve(this.appConfig);
    }

    const response = await fetch("/config.json");
    this.appConfig = await response.json();

    return this.appConfig;
  }
}

export default new ConfigService();
