import React, { memo, useEffect, useRef } from "react";
import { LoadingScreen, isEmpty } from "@q4/nimbus-ui";
import contextSubscribe from "../../context/context.subscribe";
import { AuthProps } from "./auth.definition";
import AuthService from "../../service/auth/auth.service";
import UserContext from "../../context/user/user.context";
import { PageId } from "../pages.definition";

const Auth = (props: AuthProps): JSX.Element => {
  const authService = useRef(new AuthService());

  useEffect(processLogin, []);

  function processLogin(): void {
    authService.current.processCallback().then((authUser): void => {
      if (isEmpty(authUser)) {
        props.history.push("/unauthorised");
        return;
      }
      props.user.getCurrentUser();
      props.history.replace(authUser.state || "/");
    });
  }

  return (
    <div id={PageId.Auth}>
      <LoadingScreen />
    </div>
  );
};

export default contextSubscribe<AuthProps>([{ context: UserContext, mapToProps: "user" }], memo(Auth));
