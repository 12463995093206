// #region Imports
import "./header.component.scss";
import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderProps } from "./header.definition";
import UserContext from "../../../context/user/user.context";
import contextSubscribe from "../../../context/context.subscribe";
import { Button, ButtonTheme, ButtonSize } from "@q4/nimbus-ui";
// #endregion

const Header = (props: HeaderProps): JSX.Element => {
  const { user } = props;

  function fetchUser(): void {
    user.getCurrentUser();
  }

  useEffect(fetchUser, []);

  return (
    <div className="header">
      <Link to="/">
        <div className="header_q4-icon q4i-logo"></div>
      </Link>
      <div className="header_title">Admin</div>
      {user.currentUser && (
        <Button id="LogoutButton" className="header_user-button" theme={ButtonTheme.Ink} linkTo="/logout" label="Logout" size={ButtonSize.Large} />
      )}
    </div>
  );
};

export default contextSubscribe<HeaderProps>([{ context: UserContext, mapToProps: "user" }], memo(Header));
