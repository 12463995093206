export class Client {
  id: string;
  name: string;
  createdAt: Date;
  active: boolean;

  constructor(client: Partial<Client>) {
    const { id, name, createdAt, active } = client ?? {};
    this.id = id;
    this.name = name;
    this.createdAt = typeof createdAt === "string" ? new Date(createdAt) : createdAt;
    this.active = active;
  }
}
