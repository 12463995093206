import React, { ElementType, memo } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";

const Admin = (props: AdminProps): JSX.Element => {
  const { header: Header, page: Page, ...rest } = props;
  return (
    <>
      <Header />
      <Page {...rest} />
    </>
  );
};

export default memo(withRouter(Admin));

interface AdminProps extends RouteComponentProps {
  header: ElementType;
  page: ElementType;
}
