import { RouteComponentProps } from "react-router-dom";
import { ClientContextState } from "../../context/client/client.definition";

export interface ClientListProps extends RouteComponentProps {
  client: ClientContextState;
}

export const ColumnDefinitions = [
  {
    field: "name",
    headerName: "CLIENT",
  },
];

export const LanguageDefinitions = {
  Banner: "Clients",
  Toolbar: "All Clients",
};

const PageId = "ClientList";

export const IdDefinitions = {
  Page: PageId,
  Banner: `${PageId}Banner`,
  Toolbar: `${PageId}ToolbarTitle`,
  Search: `${PageId}Search`,
  Grid: `${PageId}Grid`,
};

const PageClassName = "client-list-";

export const ClassNameDefinitions = {
  Page: `${PageClassName}page`,
  Grid: `${PageClassName}page_grid`,
};

export const IconDefinitions = {
  Company: "q4i-company_2pt",
};
