import React, { memo, useEffect, useRef } from "react";
import { LoadingScreen } from "@q4/nimbus-ui";
import AuthService from "../../service/auth/auth.service";
import { PageId } from "../pages.definition";

const Login = (): JSX.Element => {
  const authService = useRef(new AuthService());

  useEffect((): void => {
    authService.current.login("/");
  }, []);

  return (
    <div id={PageId.Login}>
      <LoadingScreen />
    </div>
  );
};

export default memo(Login);
