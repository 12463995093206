import "./textWithCount.component.scss";
import React, { memo } from "react";
import { TextWithCountProps } from "./textWithCount.definition";
import { getClassName, Ghostable } from "@q4/nimbus-ui";

function TextWithCount(props: TextWithCountProps): JSX.Element {
  const { className, id, text, count, countGhosted } = props;

  const baseClassName = getClassName("text-with-count", [{ condition: !!className, trueClassName: className }]);

  return (
    <span className={baseClassName} id={id}>
      <span key="toolbar-title" className="text-with-count_text">
        {text}
      </span>
      <Ghostable key="toolbar-count" className="text-with-count_count" ghosted={countGhosted}>
        <span>{count ?? 0}</span>
      </Ghostable>
    </span>
  );
}

TextWithCount.defaultProps = {
  count: 0,
};

export default memo(TextWithCount);
