import "./pageLayout.component.scss";
import React, { memo } from "react";
import { Banner, Spinner, SpinnerTheme, Swapable, getClassName } from "@q4/nimbus-ui";
import { PageLayoutProps } from "./pageLayout.definition";

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const { className, id, title, icon, loading, bannerProps, children } = props;

  const baseClassName = getClassName("page-layout", [{ condition: !!className, trueClassName: className }]);

  return (
    <div className={baseClassName} id={id}>
      <Swapable
        selected={+loading}
        layers={[
          <div key="page-layout_content--loaded">
            <Banner title={title} badgeIcon={icon} {...bannerProps} />
            <div className="page-layout_content">
              <div className="page-layout_content-inner">{children}</div>
            </div>
          </div>,
          <Spinner className="page-layout_spinner" key="page-layout_content--loading" theme={SpinnerTheme.Citrus} masked={false} />,
        ]}
      />
    </div>
  );
};

PageLayout.defaultProps = {
  loading: false,
};

export default memo(PageLayout);
