import React, { Context, PropsWithChildren, ElementType } from "react";

const ConsumerMapper = <T extends {}>(props: ConsumerMapperProps<T>): JSX.Element => {
  const { subscribingComponent: Component, contexts, index, ...remainingProps } = props;
  const idx = index || 0;

  if (idx >= contexts.length) {
    return <Component key={idx} {...remainingProps} />;
  }

  const { context: Context, mapToProps } = contexts[idx];
  return (
    <Context.Consumer key={idx}>
      {(contextProps: {}): JSX.Element => {
        const mappedProp = { [mapToProps]: contextProps };
        return <ConsumerMapper {...props} {...mappedProp} index={idx + 1} />;
      }}
    </Context.Consumer>
  );
};

interface ConsumerMapperModel<T> {
  context: Context<{}>;
  mapToProps: keyof T;
}

interface ConsumerMapperProps<T> extends PropsWithChildren<{}> {
  subscribingComponent: ElementType;
  contexts: ConsumerMapperModel<T>[];
  index?: number;
}

const contextSubscribe = <T extends {}>(contexts: ConsumerMapperModel<T>[], subscribingComponent: ElementType): ElementType<T> => {
  return function subscribeMapper(props: T): JSX.Element {
    return <ConsumerMapper {...props} subscribingComponent={subscribingComponent} contexts={contexts} />;
  };
};

export default contextSubscribe;
