export class EventShare {
  presentationSignedUrl: string;
  previewToken: string;

  constructor(presentationSignedUrl: string, previewToken: string) {
    this.presentationSignedUrl = presentationSignedUrl;
    this.previewToken = previewToken;
  }
}
export class Event extends EventShare {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  startDate: Date;

  constructor(event: Partial<Event>) {
    const { id, name, organizationId, organizationName, presentationSignedUrl, previewToken, startDate } = event ?? {};
    super(presentationSignedUrl, previewToken);
    this.id = id;
    this.name = name;
    this.organizationId = organizationId;
    this.organizationName = organizationName;
    this.startDate = typeof startDate === "string" ? new Date(startDate) : startDate;
  }
}

export class PublicEvent {
  name: string;
  presentationSignedUrl: string;
  resultDate: Date;
  startDate: Date;

  constructor(event: Partial<PublicEvent>) {
    const { name, presentationSignedUrl, resultDate, startDate } = event ?? {};
    this.name = name;
    this.presentationSignedUrl = presentationSignedUrl;
    this.startDate = typeof startDate === "string" ? new Date(startDate) : startDate;
    this.resultDate = typeof resultDate === "string" ? new Date(resultDate) : resultDate;
  }
}
