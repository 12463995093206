// #region Imports
import React, { memo, useState, useRef } from "react";
import moment, { Moment } from "moment";
import { getDayTimeOptions } from "../../../../helpers/date.helper";
import {
  Modal,
  Form,
  DatePicker,
  Textbox,
  Dropdown,
  ButtonTheme,
  TextboxTheme,
  DropdownTheme,
  ErrorHandlerService,
  ErrorHandlerField,
  isEmpty,
} from "@q4/nimbus-ui";
import { EventModalProps, EventModalEventState, EventModalDateFormat, EventModalErrorProps, EventModalErrorKey } from "./eventModal.definition";
// #endregion

const errorMessageService = new ErrorHandlerService<number, EventModalErrorProps>([
  new ErrorHandlerField(EventModalErrorKey.Name, "Event title is required."),
  new ErrorHandlerField(EventModalErrorKey.StartDay, "Event date is required."),
  new ErrorHandlerField(EventModalErrorKey.StartTime, "Start time is required."),
]);

function EventModal(props: EventModalProps): JSX.Element {
  const { pageId, visible, event, onSave, onCloseRequest } = props;

  const ErrorServiceId = 1;
  const editMode = !isEmpty(event);
  const formattedEvent = getFormattedEvent();
  const [name, setName] = useState<EventModalEventState["name"]>(editMode ? event?.name : null);
  const [startDay, setStartDay] = useState<EventModalEventState["startDay"]>(editMode ? formattedEvent?.startDay : null);
  const [startTime, setStartTime] = useState<EventModalEventState["startTime"]>(editMode ? formattedEvent?.startTime : null);
  const [, setErrorMessages] = useState(errorMessageService.getAll());
  const timeOptions = useRef(getDayTimeOptions(15));

  function clearState(): void {
    setName(editMode ? event?.name : null);
    setStartDay(editMode ? formattedEvent?.startDay : null);
    setStartTime(editMode ? formattedEvent?.startTime : null);
  }

  function checkForErrors(): boolean {
    const checkObject: EventModalErrorProps = {
      name,
      startDay,
      startTime,
    };
    errorMessageService.checkForErrors(ErrorServiceId, checkObject);
    setErrorMessages([...errorMessageService.getAll()]); // this is just used to trigger a rerender
    return errorMessageService.hasErrors();
  }

  function getFormattedEvent(): Pick<EventModalEventState, "startDay" | "startTime"> {
    if (!editMode) return;

    const startDate = event.startDate ? moment(event.startDate) : moment();
    const roundingDuration = moment.duration(15, "minutes");
    const roundedStartTime = moment(Math.ceil(+startDate / +roundingDuration) * +roundingDuration);

    return {
      startDay: startDate,
      startTime: roundedStartTime.format(EventModalDateFormat.StartTime),
    };
  }

  function handleNameChange(name: string): void {
    setName(name);
  }

  function handleDateChange(startDay: Moment): void {
    setStartDay(startDay);
  }

  function handleTimeChange(startTime: string): void {
    setStartTime(startTime);
  }

  function handleSaveRequest(): void {
    const hasErrors = checkForErrors();

    if (hasErrors) return;

    const startDate = moment(
      `${startDay.format(EventModalDateFormat.StartDay)} ${startTime}`,
      `${EventModalDateFormat.StartDay} ${EventModalDateFormat.StartTime}`
    )
      .utc()
      .toDate();

    onSave({
      ...event,
      name: name || "New Event",
      startDate,
    });

    !editMode && clearState();
  }

  function handleCloseRequest(): void {
    onCloseRequest();
    clearState();
    errorMessageService.clear();
  }

  const nameError = errorMessageService.get(ErrorServiceId, EventModalErrorKey.Name);
  const startDayError = errorMessageService.get(ErrorServiceId, EventModalErrorKey.StartDay);
  const startTimeError = errorMessageService.get(ErrorServiceId, EventModalErrorKey.StartTime);

  return (
    <Modal
      id={`${pageId}EventModal`}
      visible={visible}
      title={editMode ? "Edit Event" : "Create New Event"}
      footerActions={[
        {
          key: "cancel",
          id: "EventModalCancelButton",
          theme: ButtonTheme.DarkSlate,
          label: "cancel",
          onClick: handleCloseRequest,
        },
        {
          key: "save",
          id: "EventModalSaveButton",
          theme: ButtonTheme.Citrus,
          label: "save",
          onClick: handleSaveRequest,
        },
      ]}
      onCloseRequest={handleCloseRequest}
    >
      <Form
        id="EventModalForm"
        className="event-modal_form"
        fields={[
          {
            key: "title",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Event Title",
            labelHtmlFor: "EventModalFormNameInput",
            error: nameError,
            children: (
              <Textbox
                id="EventModalFormName"
                value={name}
                placeholder="Ex. Q1 Earnings Call"
                onChange={handleNameChange}
                theme={nameError.visible && TextboxTheme.Spice}
              />
            ),
          },
          {
            key: "startDate",
            width: "1-of-2",
            smallWidth: "1-of-2",
            mobileWidth: "1-of-2",
            margin: false,
            label: "Event Date",
            labelHtmlFor: "EventModalFormStartDateInput",
            error: startDayError,
            children: (
              <DatePicker
                id="EventModalFormStartDate"
                value={startDay}
                format={EventModalDateFormat.StartDay}
                placeholder="Ex. 01/01/2020"
                onChange={handleDateChange}
                textboxTheme={startDayError.visible && TextboxTheme.Spice}
              />
            ),
          },
          {
            key: "startTime",
            width: "1-of-2",
            smallWidth: "1-of-2",
            mobileWidth: "1-of-2",
            margin: false,
            label: `Start Time (${startDay?.isDST() ? "EDT" : "EST"})`,
            labelHtmlFor: "EventModalFormStartTimeInput",
            error: startTimeError,
            children: (
              <Dropdown
                id="EventModalFormStartTime"
                width="100%"
                value={startTime}
                placeholder="Ex. 12:00pm"
                options={timeOptions?.current}
                onSelect={handleTimeChange}
                theme={startTimeError?.visible && DropdownTheme.Spice}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default memo(EventModal);
