export function convertFileToBlob(file: string): Promise<Blob> {
  return fetch(file)
    .then((response): Promise<Blob> => response.blob())
    .then((blobData): Blob => blobData);
}

export function convertBlobToUrl(blob: Blob): string {
  return URL.createObjectURL(blob);
}

export function removeBlobUrl(url: string): void {
  URL.revokeObjectURL(url);
}

export function convertBlobToBinary(blob: Blob): Promise<ArrayBuffer> {
  const blobUrl = convertBlobToUrl(blob);
  return fetch(blobUrl)
    .then((response): Promise<ArrayBuffer> => response.arrayBuffer())
    .then((arrayBuffer) => {
      removeBlobUrl(blobUrl);
      return arrayBuffer;
    });
}
