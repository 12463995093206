import AuthService from "../auth/auth.service";
import UrlService from "../url/url.service";
import { ApiMethod, ApiResponse, ContentType, ResponseCode, ResponseCodeKey } from "./api.definition";
import { isEmpty } from "@q4/nimbus-ui";

export default class ApiService {
  private readonly authService = new AuthService();
  private readonly urlService = new UrlService();

  get<TResponse>(relativeUrl: string, useAuth = true): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<void, TResponse>(relativeUrl, ApiMethod.Get, useAuth);
  }

  post<TBody, TResponse = TBody>(relativeUrl: string, data: TBody, contentType = ContentType.Json, useAuth = true): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Post, useAuth, data, contentType);
  }

  put<TBody, TResponse = TBody>(relativeUrl: string, data: TBody, useAuth = true, contentType = ContentType.Json): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Put, useAuth, data, contentType);
  }

  patch<TBody, TResponse = TBody>(relativeUrl: string, data: TBody, useAuth = true, contentType = ContentType.Json): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Patch, useAuth, data, contentType);
  }

  delete<TBody, TResponse = TBody>(relativeUrl: string, data?: TBody, useAuth = true): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Delete, useAuth, data);
  }

  makeExternalRequest<TBody, TResponse = TBody>(
    absolutePath: string,
    method: string,
    data?: TBody,
    contentType = ContentType.Json
  ): Promise<Response> {
    return this.request<TBody>(`${absolutePath}`, method, contentType, data).then(this.inspectStatusCode);
  }

  async makeRequest<TBody, TResponse = TBody>(
    relativeUrl: string,
    method: string,
    useAuth = true,
    data?: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    const apiUrl = await this.urlService.getApiUrl();
    const authToken = useAuth ? this.authService.getAuthUser()?.jwtToken : null;

    return this.request<TBody>(`${apiUrl}${relativeUrl}`, method, contentType, data, authToken)
      .then(this.inspectStatusCode)
      .then((response): Promise<ApiResponse<TResponse>> => response.json())
      .then((json): ApiResponse<TResponse> => this.inspectForError<TResponse>(json))
      .catch(
        (error): ApiResponse<TResponse> => {
          const { message } = error;
          return new ApiResponse<TResponse>(false, null, message);
        }
      );
  }

  private request<TBody>(apiPath: string, method: string, contentType: string, data?: TBody, authToken?: string): Promise<Response> {
    const headers: HeadersInit = new Headers({
      "Content-Type": contentType,
      "Authorization": `Bearer ${authToken}`,
    });

    if (isEmpty(authToken)) {
      headers.delete("Authorization");
    }
    if (contentType === ContentType.FormData) {
      headers.delete("Content-type");
    }
    const body = !isEmpty(data) && contentType === ContentType.Json ? JSON.stringify(data) : data;

    const options: RequestInit = {
      method,
      headers,
      body: body as BodyInit,
    };
    return fetch(apiPath, options);
  }

  inspectStatusCode = (response: Response): Response => {
    const { status } = response;

    if (ResponseCode.Ok === status) return response;
    if (Object.values(ResponseCode).includes(status)) {
      throw new Error(ResponseCodeKey[status]);
    }
    throw new Error(`An error occurred: Status Code(${status})`);
  };

  private inspectForError = <T>(response: ApiResponse<T>): ApiResponse<T> => {
    if (!isEmpty(response)) {
      const { errors, success } = response;
      !success && console.error(`API error: ${errors}`);
      return response;
    } else {
      const errorMessage = "An error occurred";
      throw new Error(errorMessage);
    }
  };
}
