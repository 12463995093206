// #region Imports
import "./clientList.page.scss";
import React, { memo, useEffect, useState, useRef } from "react";
import type { GridApi, GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { Table, Toolbar, ToolbarTitle, Search, NotificationService } from "@q4/nimbus-ui";
import contextSubscribe from "../../context/context.subscribe";
import ClientContext from "../../context/client/client.context";
import {
  ClientListProps,
  ColumnDefinitions,
  LanguageDefinitions,
  IdDefinitions,
  ClassNameDefinitions,
  IconDefinitions,
} from "./clientList.definition";
import PageLayout from "../components/pageLayout/pageLayout.component";
import { TextWithCount } from "../../components/components.index";
// #endregion

/**
 * Client List Page
 * @param props
 * @constructor
 */
function ClientList(props: ClientListProps): JSX.Element {
  const { client, history } = props;
  const { clientList } = client;
  const [params, setParams] = useState({ search: "" });
  const [loading, setLoading] = useState(true);
  const gridApi = useRef<GridApi>();
  const notificationService = useRef(new NotificationService());

  const columnDefs = ColumnDefinitions.map((columnDef) => ({
    ...columnDef,
    flex: 1,
  }));

  const defaultColDef = {
    lockPosition: true,
    sortable: true,
    suppressMovable: true,
  };

  useEffect(getClientList, []);

  /**
   * Get all clients from context
   */
  function getClientList(): void {
    client.get().then((): void => {
      setLoading(false);
    });
  }

  /**
   * Handle onInputChange event
   */
  function handleSearchInputChange(search: string): void {
    setParams({ search });
  }

  /**
   * Handle onClear Event
   */
  function handleSearchQueryClear(): void {
    gridApi.current && gridApi.current.setQuickFilter(null);
  }

  /**
   * Handle onQueryRequest event
   */
  function handleSearchQueryRequest(): void {
    gridApi.current && gridApi.current.setQuickFilter(params.search);
  }

  /**
   * Handle onGridReady event
   */
  function handleGridReady(grid: GridReadyEvent): void {
    gridApi.current = grid?.api;
  }

  /**
   * Handle onRowClicked event
   */
  function handleRowClick(event: RowClickedEvent): void {
    const { id } = event.data;
    notificationService.current.dismiss();
    history.push(`clients/${id}/events`);
  }

  return (
    <PageLayout
      className={ClassNameDefinitions.Page}
      id={IdDefinitions.Page}
      title={LanguageDefinitions.Banner}
      icon={IconDefinitions.Company}
      bannerProps={{
        id: IdDefinitions.Banner,
      }}
    >
      <Toolbar>
        <ToolbarTitle
          id={IdDefinitions.Toolbar}
          title={<TextWithCount text={LanguageDefinitions.Toolbar} count={clientList?.length} countGhosted={loading} />}
        />
        <Search
          id={IdDefinitions.Search}
          value={params.search}
          onInputChange={handleSearchInputChange}
          onClear={handleSearchQueryClear}
          onQueryRequest={handleSearchQueryRequest}
        />
      </Toolbar>
      <div className={ClassNameDefinitions.Grid}>
        <Table
          id={IdDefinitions.Grid}
          loading={loading}
          key="client-list--loaded"
          columnDefs={columnDefs}
          rowData={clientList}
          defaultColDef={defaultColDef}
          accentedSort
          cacheQuickFilter
          onGridReady={handleGridReady}
          onRowClicked={handleRowClick}
        />
      </div>
    </PageLayout>
  );
}

export default contextSubscribe<ClientListProps>([{ context: ClientContext, mapToProps: "client" }], memo(ClientList));
