import ApiService from "../api/api.service";
import { ApiMethod, ApiResponse, ContentType } from "../api/api.definition";
import { Event as EventModel, PublicEvent as PublicEventModel, EventShare } from "./event.model";
import { convertBlobToBinary } from "../../helpers/file.helper";

interface PresentationUpload {
  filePath: string;
}

interface PresentationUploadResponse {
  uploadUrl: string;
  filePath: string;
}

export default class EventService {
  private readonly API_PATH = "/events";
  private readonly ORGANIZATION_PATH = "/organization";
  private apiService = new ApiService();

  getPreview(id: string, token: string): Promise<ApiResponse<PublicEventModel>> {
    return this.apiService.get<PublicEventModel>(`${this.API_PATH}/${id}/preview?token=${token}`, false).then(this.mapPublicEvent);
  }

  getPublic(id: string): Promise<ApiResponse<PublicEventModel>> {
    return this.apiService.get<PublicEventModel>(`${this.API_PATH}/${id}/presentation`, false).then(this.mapPublicEvent);
  }

  getById(id: string): Promise<ApiResponse<EventModel>> {
    return this.apiService.get<EventModel>(`${this.API_PATH}/${id}`).then(this.mapEventByIdReponse);
  }

  getByClient(id: string): Promise<ApiResponse<EventModel[]>> {
    return this.apiService.get<EventModel[]>(`${this.ORGANIZATION_PATH}/${id}${this.API_PATH}`).then(this.mapEventByClientResponse);
  }

  addEvent(data: Event): Promise<ApiResponse<EventModel>> {
    return this.apiService.post<EventModel>(`${this.API_PATH}`, data).then(this.mapEventByIdReponse);
  }

  editEvent(id: string, data: Event): Promise<ApiResponse<EventModel>> {
    return this.apiService.put<EventModel>(`${this.API_PATH}/${id}`, data).then(this.mapEventByIdReponse);
  }

  putPresentation(id: string, presentation: File): Promise<ApiResponse<EventShare>> {
    return this.getPresentationUploadUrl(id)
      .then(
        (response): PresentationUploadResponse => {
          if (!response.success) {
            throw new Error(`${response.errors}`);
          }

          const { data: uploadResponse } = response;
          return uploadResponse;
        }
      )
      .then(
        (uploadResponse): Promise<string> => {
          const { filePath, uploadUrl } = uploadResponse;
          return this.uploadPresentation(filePath, uploadUrl, presentation);
        }
      )
      .then(
        (filePath): Promise<ApiResponse<EventShare>> => {
          return this.apiService.put<PresentationUpload, EventShare>(`${this.API_PATH}/${id}/uploadstatus`, { filePath });
        }
      )
      .catch(
        (e): ApiResponse<null> => {
          return new ApiResponse(false, e);
        }
      );
  }

  private mapPublicEvent(response: ApiResponse<PublicEventModel>): ApiResponse<PublicEventModel> {
    return new ApiResponse(response?.success, new PublicEventModel(response?.data), ...(response?.errors ?? []));
  }

  private mapEventByIdReponse(response: ApiResponse<EventModel>): ApiResponse<EventModel> {
    return new ApiResponse(response?.success, new EventModel(response?.data), ...(response?.errors ?? []));
  }

  private mapEventByClientResponse(response: ApiResponse<EventModel[]>): ApiResponse<EventModel[]> {
    return new ApiResponse(
      response?.success,
      (response?.data ?? []).map((event) => new EventModel(event)),
      ...(response?.errors ?? [])
    );
  }

  private getPresentationUploadUrl(id: string): Promise<ApiResponse<PresentationUploadResponse>> {
    return this.apiService.get<PresentationUploadResponse>(`${this.API_PATH}/${id}/uploadurl`);
  }

  private uploadPresentation(filePath: string, url: string, file: File): Promise<string> {
    return convertBlobToBinary(file).then(
      (arrayBuffer): Promise<string> => {
        const buffer = Buffer.from(arrayBuffer);
        return this.apiService.makeExternalRequest<Buffer, boolean>(url, ApiMethod.Put, buffer, ContentType.Pdf).then(() => {
          return filePath;
        });
      }
    );
  }
}
export type PublicEvent = PublicEventModel;
export type Event = EventModel;
