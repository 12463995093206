import React, { createContext, PropsWithChildren, useRef, useState } from "react";
import { UserContextState as UserContextStateModel } from "./user.definition";
import AuthService from "../../service/auth/auth.service";
import { AuthUser } from "../../models/authUser.interface";

const UserContext = createContext<Partial<UserContextState>>({});
export const UserProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const authService = useRef(new AuthService());
  const [user, setUser] = useState<AuthUser>(null);

  const getUser = (): void => {
    setUser(authService.current.getAuthUser());
  };

  return (
    <UserContext.Provider
      value={{
        getCurrentUser: getUser,
        currentUser: user,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
export type UserContextState = UserContextStateModel;
