/* eslint-disable @typescript-eslint/camelcase */
import Oidc from "oidc-client";
import ConfigService from "../../service/config/config.service";
import TokenService from "../../service/auth/token.service";
import { AuthUser } from "../../models/authUser.interface";

export default class AuthService {
  private initialized: boolean;
  private oidcManager: Oidc.UserManager;
  private tokenService: TokenService;

  constructor() {
    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.INFO;

    this.tokenService = new TokenService();
  }

  private async init(): Promise<void> {
    const config = await ConfigService.get();

    this.oidcManager = new Oidc.UserManager({
      authority: config.oneLogin.issuer,
      client_id: config.oneLogin.audience,
      redirect_uri: config.oneLogin.redirectUri,
      response_type: "id_token token",
      scope: "openid profile params",
      filterProtocolClaims: true,
      loadUserInfo: true,
    });

    this.initialized = true;
  }

  async login(returnUrl: string): Promise<void> {
    if (!this.initialized) {
      await this.init();
    }
    this.oidcManager.signinRedirect({ state: returnUrl });
  }

  isAuthenticated(): boolean {
    const token = this.tokenService.getToken();
    const isAuthenticated = token && token.expiryTime && token.expiryTime > new Date().getTime() / 1000;

    if (!isAuthenticated) {
      this.tokenService.removeToken();
    }

    return isAuthenticated;
  }

  getAuthUser(): AuthUser {
    return this.tokenService.getToken();
  }

  async logOut(): Promise<void> {
    if (!this.initialized) {
      await this.init();
    }

    this.tokenService.removeToken();
    this.oidcManager.clearStaleState();
  }

  async processCallback(): Promise<AuthUser> {
    if (!this.initialized) {
      await this.init();
    }

    let user: Oidc.User;

    try {
      user = await this.oidcManager.signinRedirectCallback();
    } catch (e) {
      console.error(e);
      return null;
    }

    console.log("Signed in", user);

    const authUser: AuthUser = {
      email: user.profile.email,
      expiryTime: user.expires_at,
      name: user.profile.name,
      jwtToken: user.id_token,
      state: user.state,
    };

    this.tokenService.setToken(authUser);

    return authUser;
  }
}
