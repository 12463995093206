import "./viewer.page.scss";
import LockedDocumentImage from "../../assets/images/lockedDocuments.svg";
import React, { memo, useEffect, useState, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { convertFileToBlob } from "../../helpers/file.helper";
import EventService from "../../service/event/event.service";
import { ButtonTheme, Spinner, SpinnerTheme, isNullOrWhiteSpace, Swapable } from "@q4/nimbus-ui";
import { PublicEvent } from "../../service/event/event.model";
import { ResponseMessage } from "../../service/api/api.definition";
import { EventMatchParams, ViewerQueryParam } from "./viewer.definition";
import SlideViewer from "../../components/slideViewer/slideViewer.component";
import { SlideViewerPlaceholderProps } from "../../components/slideViewer/slideViewer.definition";
import { PageId } from "../pages.definition";

const Viewer = (props: RouteComponentProps<EventMatchParams>): JSX.Element => {
  const Retries = [1000, 2000, 3000];
  const [event, setEvent] = useState<PublicEvent>(null);
  const [failed, setFailed] = useState(false);
  const [responseMessage, setResponseMessage] = useState<ResponseMessage>();
  const [file, setFile] = useState<Blob>(null);
  const [loading, setLoading] = useState(true);
  const eventService = useRef(new EventService());

  // #region Lifecycle Methods
  useEffect(getEvent, []);
  // #endregion

  // #region Helper Methods
  function getEvent(): void {
    const { eventId } = props.match?.params;
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const token = params?.get(ViewerQueryParam.Token);

    if (isNullOrWhiteSpace(eventId)) {
      handleFailedPresentation(ResponseMessage.NotFound);
      setLoading(false);
      return;
    }

    if (!isNullOrWhiteSpace(token)) {
      handlePreviewRequest(eventId, token);
      return;
    }
    handlePublicRequest(eventId);
  }

  function getPresentationFile(url: string): void {
    convertFileToBlob(url)
      .then((file): void => {
        setFile(file);
      })
      .catch((e): void => {
        handleFailedPresentation(e.message);
      });
  }

  function getPlaceholderProps(): SlideViewerPlaceholderProps {
    switch (responseMessage) {
      case ResponseMessage.Unauthorized:
        return new SlideViewerPlaceholderProps({
          image: LockedDocumentImage,
          title: "This presentation is unavailable",
          subtitle: "Please check back at a later time",
        });
      case ResponseMessage.NotFound:
        return new SlideViewerPlaceholderProps({
          image: null,
          title: "Sorry, the presentation is in another castle...",
          subtitle: "",
        });
      default:
        return new SlideViewerPlaceholderProps({
          title: "Oops, something went wrong",
          subtitle: "Please try reloading the presentation",
          actions: [
            {
              id: "SlideViewerErrorReload",
              label: "Reload",
              theme: ButtonTheme.Rain,
              onClick: reloadPresentation,
            },
          ],
        });
    }
  }

  function reloadPresentation(): void {
    setLoading(true);
    getEvent();
  }
  // #endregion

  // #region Handle Method
  function handlePreviewRequest(eventId: string, token: string): void {
    eventService.current
      .getPreview(eventId, token)
      .then((response): void => {
        if (!response?.success) {
          const errorMessage = response?.errors[0] as ResponseMessage;
          handleFailedPresentation(errorMessage);
          return;
        }

        setEvent(event);
        getPresentationFile(response?.data?.presentationSignedUrl);
      })
      .then((): void => {
        setLoading(false);
      });
  }

  function handlePublicRequest(eventId: string, retry = 0): void {
    eventService.current
      .getPublic(eventId)
      .then((response): boolean => {
        if (!!response?.success) {
          setEvent(event);
          getPresentationFile(response?.data?.presentationSignedUrl);
          return false;
        }

        // Retry if there is the following errors
        const errorMessage = response?.errors[0] as ResponseMessage;
        if (
          [ResponseMessage.TooManyRequests, ResponseMessage.InternalServerError, ResponseMessage.GatewayTimeout].includes(errorMessage) &&
          retry <= Retries.length - 1
        ) {
          const nextTime = Retries[retry];
          console.error(`${errorMessage}, retry in ${nextTime}ms`);

          const nextAttempt = setTimeout((): void => {
            handlePublicRequest(eventId, retry + 1);
            clearTimeout(nextAttempt);
          }, nextTime);

          return true;
        }

        handleFailedPresentation(errorMessage);
        return false;
      })
      .then((loading): void => {
        setLoading(loading);
      });
  }

  function handleFailedPresentation(message: ResponseMessage): void {
    console.error(message);
    setResponseMessage(message);
    setFailed(true);
  }
  // #endregion

  return (
    <div className="viewer" id={PageId.PublicViewer}>
      <Swapable
        selected={+loading}
        layers={[
          <SlideViewer id={PageId.PublicViewer} key="viewer--live" failed={failed} file={file} placeholderProps={getPlaceholderProps()} />,
          <Spinner key="viewer--loading" theme={SpinnerTheme.Rain} masked={false} />,
        ]}
      />
    </div>
  );
};

export default memo(Viewer);
