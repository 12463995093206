import "./fileDropzone.component.scss";
import DocumentImage from "../../assets/images/documents.png";
import React, { memo, Ref, RefObject, useRef, MouseEvent } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { ButtonSize, ButtonTheme, isEmpty, PlaceholderContent, PlaceholderContentTheme, NotificationService } from "@q4/nimbus-ui";
import { FileDropzoneProps } from "./fileDropzone.definition";

// eslint-disable-next-line react/display-name
const FileDropzone = React.forwardRef(
  (props: FileDropzoneProps, ref: Ref<DropzoneRef>): JSX.Element => {
    const { accepts, onDrop } = props;
    const notificationService = useRef(new NotificationService());
    const dropzoneRef = (ref as RefObject<DropzoneRef>) ?? useRef<DropzoneRef>();

    function handleDrop(accepted: File[]): void {
      notificationService.current.dismiss();

      const file = accepted[0];
      if (isEmpty(file)) {
        notificationService.current.error("Unsupported file.");
        return;
      }

      !isEmpty(onDrop) && onDrop(file);
    }

    function handleOpen(e: MouseEvent): void {
      e.stopPropagation();
      dropzoneRef.current?.open();
    }

    const uploadAction = [
      {
        id: "DropzoneBrowse",
        className: "dropzone-browse",
        label: "Browse",
        theme: ButtonTheme.Rain,
        onClick: handleOpen,
        size: ButtonSize.Large,
      },
    ];

    return (
      <Dropzone accept={accepts} onDrop={handleDrop} ref={ref}>
        {({ getRootProps, getInputProps }): JSX.Element => (
          <div className="dropzone" {...getRootProps()} tabIndex={-1}>
            <input {...getInputProps()} />
            <PlaceholderContent
              id="DropzonePlaceholder"
              className="dropzone-placeholder"
              theme={PlaceholderContentTheme.Light}
              image={DocumentImage}
              title="Drag and drop your file"
              subtitle="or"
              actions={uploadAction}
              caption="Only .pdf files are accepted"
            />
          </div>
        )}
      </Dropzone>
    );
  }
);
export default memo(FileDropzone);
