import { AuthUser } from "../../models/authUser.interface";

export default class TokenService {
  private readonly storageKey: string;

  constructor() {
    this.storageKey = "AuthUser";
  }

  getToken(): AuthUser {
    const json = localStorage.getItem(this.storageKey);
    if (json) {
      return JSON.parse(json);
    }
    return null;
  }

  setToken(authUser: AuthUser): void {
    localStorage.setItem(this.storageKey, JSON.stringify(authUser));
  }

  removeToken(): void {
    localStorage.removeItem(this.storageKey);
  }

  getJwtToken(): string {
    const token = this.getToken();
    if (token && token.jwtToken) {
      return token.jwtToken;
    }

    return null;
  }
}
