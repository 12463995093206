import "./unauthorised.page.scss";
import React, { memo } from "react";
import { PlaceholderContent, PlaceholderContentTheme, LayoutHeight } from "@q4/nimbus-ui";
import { PageId } from "../pages.definition";

const Unauthorised = (): JSX.Element => {
  return (
    <div id={PageId.Unauthorised} className="unauthorised">
      <PlaceholderContent
        icon="q4i-logo"
        theme={PlaceholderContentTheme.Dark}
        title="Unauthorised Access"
        subtitle="Please speak to your OneLogin Admin to request access to webcasting"
        layoutProps={{
          height: LayoutHeight.Full,
        }}
      />
    </div>
  );
};

export default memo(Unauthorised);
