// #region Imports
import "./clientDetails.page.scss";
import React, { memo, useEffect, useRef, useState } from "react";
import moment from "moment";
import type { GridApi, GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { ValueFormatterParams, CellClassParams, GetQuickFilterTextParams } from "ag-grid-community/dist/lib/entities/colDef";
import {
  Table,
  ButtonTheme,
  Toolbar,
  ToolbarTitle,
  Search,
  isNullOrWhiteSpace,
  isEmpty,
  BannerControlType,
  NotificationService,
} from "@q4/nimbus-ui";
import contextSubscribe from "../../../context/context.subscribe";
import OrganizationContext from "../../../context/client/client.context";
import EventContext from "../../../context/event/event.context";
import { formatUtcDateToET } from "../../../helpers/date.helper";
import { PageId } from "../../pages.definition";
import { ClientDetailsProps, EventStatusLabel } from "./clientDetails.definition";
import { TextWithCount } from "../../../components/components.index";
import { Event } from "../../../service/event/event.model";
import PageLayout from "../../components/pageLayout/pageLayout.component";
import EventModal from "../../event/components/modal/eventModal.component";
// #endregion

function ClientDetails(props: ClientDetailsProps): JSX.Element {
  const { event, client, history, match } = props;
  const { clientId } = match?.params;
  const { clientById } = client;
  const { eventsByClient } = event;
  const { id: organizationId, name: organizationName } = clientById ?? {};

  const [params, setParams] = useState({ search: "" });
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [clientLoading, setClientLoading] = useState(true);
  const [eventListLoading, setEventListLoading] = useState(true);
  const gridApi = useRef<GridApi>();
  const notificationService = useRef(new NotificationService());

  const defaultColDef = {
    sortable: true,
    lockPosition: true,
    suppressMovable: true,
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "startDate",
      headerName: "Date & Time",
      valueFormatter: ({ value }: ValueFormatterParams): string => formatUtcDateToET(value),
      sort: "desc",
      getQuickFilterText: ({ value }: GetQuickFilterTextParams): string => moment(value).format("LLLL"),
    },
    {
      field: "startDate",
      headerName: "Status",
      valueFormatter: (params: ValueFormatterParams): string => {
        return getEventStatus(params?.value);
      },
      cellClass: "client-details-page_status",
      cellClassRules: {
        "client-details-page_status--upcoming": (params: CellClassParams): boolean => {
          return getEventStatus(params?.value) === EventStatusLabel.Upcoming;
        },
        "client-details-page_status--live": (params: CellClassParams): boolean => {
          return getEventStatus(params?.value) === EventStatusLabel.Live;
        },
      },
      getQuickFilterText: (): string => "",
    },
  ];

  useEffect(handleClientIdChange, [clientId]);

  /**
   * Handle event where clientId changes as match param
   */
  function handleClientIdChange(): void {
    client.getById(clientId).then((success): void => {
      if (!success) {
        handleBackRequest();
        return;
      }

      setClientLoading(false);
      event.getByClient(clientId).then((success): void => {
        if (!success) {
          handleBackRequest();
          return;
        }
        setEventListLoading(false);
      });
    });
  }

  /**
   * Get event status based on date
   * @param date
   */
  function getEventStatus(date: string): string {
    if (isNullOrWhiteSpace(date)) {
      return null;
    }

    const eventDate = moment.utc(date);
    const eventStart = eventDate.clone().subtract(16, "minutes");
    const eventEnd = eventDate.clone().add(1, "days");
    const currentDate = moment.utc();

    if (currentDate.isAfter(eventEnd)) {
      return EventStatusLabel.Archived;
    }

    if (currentDate.isAfter(eventStart)) {
      return EventStatusLabel.Live;
    }

    return EventStatusLabel.Upcoming;
  }

  /**
   * Handle onBackRequest
   */
  function handleBackRequest(): void {
    history.push("/clients");
  }

  /**
   * Handle Banner's add button onClick event
   */
  function handleEventAddRequest(): void {
    notificationService.current.dismiss();
    setEventModalOpen(true);
  }

  /**
   * Handle EventModal's onCloseRequest event
   */
  function handleEventModalCloseRequest(): void {
    setEventModalOpen(false);
  }

  /**
   * Handle EventModal's onSave event
   */
  function handleEventModalSave(data: Event): void {
    if (isEmpty(data)) {
      return;
    }

    setEventListLoading(true);
    event
      .post({
        organizationId,
        organizationName,
        ...data,
      })
      .then((success): void => {
        if (!success) {
          return;
        }

        event.getByClient(clientId, true).then((): void => {
          setEventListLoading(false);
        });
      });

    handleEventModalCloseRequest();
  }

  /**
   * Handle onInputChange event
   * @param search
   */
  function handleSearchInputChange(search: string): void {
    setParams({ search });
  }

  /**
   * Handle onClear event
   */
  function handleSearchQueryClear(): void {
    gridApi.current && gridApi.current.setQuickFilter(null);
  }

  /**
   * Handle onQueryRequest event
   */
  function handleSearchQueryRequest(): void {
    gridApi.current && gridApi.current.setQuickFilter(params.search);
  }

  /**
   * Handle onGridReady event
   * @param grid
   */
  function handleGridReady(grid: GridReadyEvent): void {
    gridApi.current = grid?.api;
  }

  /**
   * Handle onRowClicked event
   * @param event
   */
  function handleRowClick(event: RowClickedEvent): void {
    const { id } = event.data;
    notificationService.current.dismiss();
    history.push(`/clients/${clientId}/events/${id}/edit`);
  }

  return (
    <PageLayout
      id={PageId.ClientDetails}
      className="client-details-page"
      title={organizationName}
      icon="q4i-company_2pt"
      loading={clientLoading}
      bannerProps={{
        id: `${PageId.ClientDetails}Banner`,
        controls: [
          {
            type: BannerControlType.Button,
            props: {
              id: `${PageId.ClientDetails}AddButton`,
              theme: ButtonTheme.Citrus,
              icon: "q4i-add_4pt",
              onClick: handleEventAddRequest,
            },
          },
        ],
        onBackRequest: handleBackRequest,
      }}
    >
      <Toolbar>
        <ToolbarTitle
          id={`${PageId.ClientDetails}ToolbarTitle`}
          title={<TextWithCount text="Events" count={eventsByClient?.length} countGhosted={eventListLoading} />}
        />
        <Search
          id={`${PageId.ClientDetails}Search`}
          value={params.search}
          onInputChange={handleSearchInputChange}
          onClear={handleSearchQueryClear}
          onQueryRequest={handleSearchQueryRequest}
        />
      </Toolbar>
      <div className="client-details-page_grid">
        <Table
          id={`${PageId.ClientDetails}Grid`}
          key="client-events--loaded"
          loading={eventListLoading}
          columnDefs={columnDefs}
          rowData={eventsByClient}
          defaultColDef={defaultColDef}
          accentedSort
          cacheQuickFilter
          onGridReady={handleGridReady}
          onRowClicked={handleRowClick}
        />
      </div>

      <EventModal
        pageId={PageId.ClientDetails}
        visible={eventModalOpen}
        onSave={handleEventModalSave}
        onCloseRequest={handleEventModalCloseRequest}
      />
    </PageLayout>
  );
}

export default contextSubscribe<ClientDetailsProps>(
  [
    { context: EventContext, mapToProps: "event" },
    { context: OrganizationContext, mapToProps: "client" },
  ],
  memo(ClientDetails)
);
