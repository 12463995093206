import React, { createContext, PropsWithChildren, useState, useRef } from "react";
import { ClientContextState as ClientContextStateModel } from "./client.definition";
import ClientService, { Client } from "../../service/client/client.service";
import { isEmpty, NotificationService } from "@q4/nimbus-ui";

const ClientContext = createContext<Partial<ClientContextState>>({});

export const ClientProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const clientsService = useRef(new ClientService());
  const [clientById, setClientById] = useState<Client>();
  const [clientList, setClientList] = useState<Client[]>();
  const notificationService = useRef(new NotificationService());

  const _setClient = (id: string, clients: Client[]): boolean => {
    const client = clients?.find((organization) => organization.id === id) ?? null;
    if (isEmpty(client)) {
      notificationService.current.error("Failed to get client.");
      return false;
    }
    setClientById(client);
    return true;
  };

  const _load = (): Promise<Client[]> => {
    return clientsService.current.get().then((response): Client[] => {
      if (!response.success) {
        throw new Error("Failed to get all clients.");
      }

      const { data: clientList } = response;
      setClientList(clientList);
      return clientList;
    });
  };

  const get = (): Promise<boolean> => {
    if (!isEmpty(clientList)) return Promise.resolve(true);

    return _load()
      .then((): boolean => true)
      .catch(handleError);
  };

  const getById = (id: string): Promise<boolean> => {
    if (isEmpty(clientList)) {
      return _load()
        .then((clientList): boolean => {
          return _setClient(id, clientList);
        })
        .catch(handleError);
    }

    return Promise.resolve(_setClient(id, clientList));
  };

  function handleError(error: Error): boolean {
    notificationService.current.error(error.message);
    return false;
  }

  return (
    <ClientContext.Provider
      value={{
        clientById,
        clientList,
        get,
        getById,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientContext;

export type ClientContextState = ClientContextStateModel;
