import React, { useEffect, useState, useRef, memo } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import { LoadingScreen } from "@q4/nimbus-ui";
import AuthService from "../../../service/auth/auth.service";
import { PrivateRouteProps } from "./privateRoute.definition";

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, ...rest } = props;

  const authService = useRef(new AuthService());
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthentication = (): void => {
    if (authService.current.isAuthenticated()) {
      setIsAuthenticated(true);
    } else {
      authService.current.login(props.location.pathname);
    }
  };

  function handleRouteChange(): () => void {
    handleAuthentication();

    return (): void => {
      handleAuthentication();
    };
  }

  useEffect(handleRouteChange, [props.location.pathname]);

  return <Route {...rest} render={(props): JSX.Element => (isAuthenticated === true ? <Component {...props} /> : <LoadingScreen />)} />;
};

export default memo(withRouter(PrivateRoute));
