import moment from "moment";

export function isDateValid(date: Date): boolean {
  return Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime());
}

export function formatUtcDateToET(utcDate: Date, dateFormat = "MM/DD/YY @h:mma"): string {
  if (!isDateValid(utcDate)) return "";

  const utcMoment = moment.utc(utcDate);
  const isDST = utcMoment.local().isDST();
  const offsetDate = utcMoment.utcOffset(isDST ? "-04:00" : "-05:00").format(dateFormat);

  return `${offsetDate} ${isDST ? "EDT" : "EST"}`;
}

export const getDayTimeOptions = (minuteSkip: number, format = "h:mma"): string[] => {
  const startTime = moment().startOf("day");
  const endTime = moment().endOf("day");
  const timeOptions = [];

  while (startTime.isBefore(endTime)) {
    timeOptions.push(startTime.format(format));
    startTime.add(minuteSkip || 15, "minutes");
  }

  return timeOptions;
};
