// #region Imports
import "./eventDetails.page.scss";
import React, { memo, useEffect, useState, useRef, RefObject } from "react";
import { DropzoneRef } from "react-dropzone";
import {
  ButtonTheme,
  isEmpty,
  isNullOrWhiteSpace,
  Ghostable,
  Spinner,
  Swapable,
  Opacity,
  SpinnerTheme,
  BannerControlType,
  ButtonSize,
  NotificationService,
} from "@q4/nimbus-ui";
import { convertFileToBlob } from "../../../helpers/file.helper";
import { formatUtcDateToET } from "../../../helpers/date.helper";
import contextSubscribe from "../../../context/context.subscribe";
import EventContext from "../../../context/event/event.context";
import { Event } from "../../../service/event/event.model";
import { PageId } from "../../pages.definition";
import { ContentType } from "../../../service/api/api.definition";
import { EventDetailsProps } from "./eventDetails.definition";
import PageLayout from "../../components/pageLayout/pageLayout.component";
import FileDropzone from "../../../components/fileDropzone/fileDropzone.component";
import SlideViewer from "../../../components/slideViewer/slideViewer.component";
import { SlideViewerPlaceholderProps } from "../../../components/slideViewer/slideViewer.definition";
import EventModal from "../components/modal/eventModal.component";
import EventShareModal from "../components/shareModal/eventShareModal.component";
// #endregion

const EventDetails = (props: EventDetailsProps): JSX.Element => {
  const { event, history, match } = props;
  const { eventById } = event;
  const { eventId, clientId } = match?.params;

  const [file, setFile] = useState<Blob>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const notificationService = useRef(new NotificationService());
  const dropzone1Ref = useRef<DropzoneRef>();
  const dropzone2Ref = useRef<DropzoneRef>();

  const hasPresentation = !isNullOrWhiteSpace(eventById?.presentationSignedUrl);
  const placeholderProps = new SlideViewerPlaceholderProps({
    actions: [
      {
        id: "SlideViewerErrorReload",
        label: "Reload",
        theme: ButtonTheme.Rain,
        onClick: handlePresentationRequest,
      },
    ],
  });

  // #region Lifecycle Methods
  useEffect(handleFileChange, [eventById?.presentationSignedUrl]);

  useEffect(handleEventIdChange, [eventId]);
  // #endregion

  // #region Handle Methods
  function handlePresentationRequest(): void {
    setFile(null);

    const { presentationSignedUrl } = eventById;
    convertFileToBlob(presentationSignedUrl).then((file): void => {
      setFile(file);
    });
  }

  function handleFileChange(): () => void {
    setSaving(false);

    // prevent file from loading if the event from the context is empty
    // or the eventId doesn't match the url param
    if (isEmpty(eventById) || eventById?.id !== eventId) return;

    handlePresentationRequest();
  }

  function handleEventIdChange(): void {
    event.getById(eventId).then((success): void => {
      if (!success) {
        handleRouteToClientDetails();
        return;
      }

      setLoading(false);
    });
  }

  function handleRouteToClientDetails(): void {
    history.push(`/clients/${clientId}/events`);
  }

  /**
   * Handle Banner's onBackRequest event
   */
  function handleBackRequest(): void {
    notificationService.current.dismiss();
    handleRouteToClientDetails();
  }

  /**
   * Handle Banner's edit button onClick event
   */
  function handleEditRequest(): void {
    notificationService.current.dismiss();
    setEditModalOpen(true);
  }

  /**
   * Handle EventModal's onCloseRequest event
   */
  function handleEditModalCloseRequest(): void {
    setEditModalOpen(false);
  }

  /**
   * Handle EventModal's onSave event
   */
  function handleEditModalSave(data: Event): void {
    if (isEmpty(data)) {
      return;
    }

    setSaving(true);
    event.put(eventId, data).then((success): void => {
      if (!success) {
        return;
      }

      setSaving(false);
    });

    handleEditModalCloseRequest();
  }

  /**
   * Handle Banner's share button onClick event
   */
  function handleShareRequest(): void {
    setShareModalOpen(true);
    notificationService.current.dismiss();
  }

  /**
   * Handle EventShareModal's onCloseRequest event
   */
  function handleShareModalCloseRequest(): void {
    setShareModalOpen(false);
  }

  function handleDrop(file: File): void {
    setSaving(true);
    props.event?.putPresentation(eventId, file);
  }

  function handleOpen(): void {
    const ref = dropzone1Ref.current ?? dropzone2Ref.current;
    notificationService.current.dismiss();
    ref?.open();
  }
  // #endregion

  // #region Render Methods
  function renderDropzone(ref: RefObject<DropzoneRef>): JSX.Element {
    return <FileDropzone accepts={[ContentType.Pdf]} onDrop={handleDrop} ref={ref} />;
  }

  function renderPresentation(): JSX.Element {
    return (
      <Swapable
        selected={+hasPresentation}
        layers={[
          <div key="presentation-uploader-layer" className="event-details_viewer-content">
            {renderDropzone(dropzone1Ref)}
          </div>,
          <div key="presentation-viewer-layer" className="event-details_viewer-content event-details_viewer-content--loaded">
            <div style={{ display: "none", opacity: 0 }}>{renderDropzone(dropzone2Ref)}</div>
            <SlideViewer id={PageId.EventDetails} file={file} placeholderProps={placeholderProps} />
          </div>,
        ]}
      />
    );
  }
  // #endregion

  return (
    <PageLayout
      className="event-details"
      id={PageId.EventDetails}
      title={`${eventById?.organizationName} ${eventById?.name}`}
      icon="q4i-webcast_2pt"
      loading={loading}
      bannerProps={{
        id: `${PageId.EventDetails}Banner`,
        details: <span>{formatUtcDateToET(eventById?.startDate)}</span>,
        onBackRequest: handleBackRequest,
        controls: [
          {
            type: BannerControlType.Button,
            props: {
              id: `${PageId.EventDetails}EditButton`,
              theme: ButtonTheme.DarkSlate,
              size: ButtonSize.Large,
              label: "Edit Event",
              onClick: handleEditRequest,
            },
          },
          {
            type: BannerControlType.Button,
            props: {
              id: `${PageId.EventDetails}ReplaceButton`,
              theme: ButtonTheme.DarkSlate,
              size: ButtonSize.Large,
              label: "Replace File",
              disabled: !hasPresentation,
              onClick: handleOpen,
            },
          },
          {
            type: BannerControlType.Button,
            props: {
              id: `${PageId.EventDetails}ShareButton`,
              theme: ButtonTheme.Citrus,
              size: ButtonSize.Large,
              label: "Share",
              disabled: !hasPresentation,
              onClick: handleShareRequest,
            },
          },
        ],
      }}
    >
      <div className="event-details_viewer">{renderPresentation()}</div>

      <EventModal
        pageId={PageId.EventDetails}
        visible={editModalOpen}
        event={eventById}
        onSave={handleEditModalSave}
        onCloseRequest={handleEditModalCloseRequest}
      />
      <EventShareModal pageId={PageId.EventDetails} visible={shareModalOpen} event={eventById} onCloseRequest={handleShareModalCloseRequest} />

      <Ghostable ghosted={!saving}>
        <Spinner theme={SpinnerTheme.Citrus} maskOpacity={Opacity.High} />
      </Ghostable>
    </PageLayout>
  );
};

export default contextSubscribe<EventDetailsProps>([{ context: EventContext, mapToProps: "event" }], memo(EventDetails));
