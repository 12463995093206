import React, { PropsWithChildren } from "react";
import * as Providers from "./providers/providers.context";

const ContextProvider = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;
  const keys = Object.keys(Providers);

  const providerMapper = (index = 0): JSX.Element => {
    const Provider = Providers[keys[index] as keyof typeof Providers];
    return <Provider key={index}>{++index < keys.length ? providerMapper(index) : children}</Provider>;
  };

  return providerMapper();
};
export default ContextProvider;
