import ApiService from "../api/api.service";
import { ApiResponse } from "../api/api.definition";
import { Client as ClientModel } from "./client.model";

export default class ClientService {
  private readonly API_PATH = "/organizations";
  private apiService = new ApiService();

  get(): Promise<ApiResponse<Client[]>> {
    return this.apiService.get<Client[]>(this.API_PATH).then(this.mapClientResponse);
  }

  private mapClientResponse(response: ApiResponse<ClientModel[]>): ApiResponse<ClientModel[]> {
    return new ApiResponse(
      response?.success,
      (response?.data ?? []).map((client) => new ClientModel(client)),
      ...(response?.errors ?? [])
    );
  }
}
export type Client = ClientModel;
