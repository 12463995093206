import "./slideViewer.component.scss";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, ButtonTheme, Ghostable, Spinner, PlaceholderContent, PlaceholderContentTheme, KeyboardKey } from "@q4/nimbus-ui";
import { SlideViewerProps } from "./slideViewer.definition";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Link to react-pdf: https://github.com/wojtekmaj/react-pdf/

const SlideViewer = (props: SlideViewerProps): JSX.Element => {
  const DefaultId = "Slides";
  const InitialPage = 1;
  const ViewerScale = 1;
  const [aspectRatio, setAspectRatio] = useState(0);
  const [currentPage, setCurrentPage] = useState(InitialPage);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [pages, setPages] = useState(1);
  const containerRef = useRef<HTMLDivElement>();

  const { dataId, failed, file, id, placeholderProps } = props;
  const componentId = `${id ?? ""}${DefaultId}`;
  const componentDataId = `${dataId ?? ""}${DefaultId}`;

  // #region Lifecycle Methods
  useEffect((): (() => void) => {
    function handleResize(): void {
      const bounds = containerRef.current?.getBoundingClientRect();
      const presentationHeight = bounds?.height;
      const presentationWidth = presentationHeight * aspectRatio;

      if (presentationWidth >= bounds?.width) {
        setHeight(null);
        setWidth(bounds?.width);
      } else {
        setHeight(presentationHeight);
        setWidth(null);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [aspectRatio]);
  // #endregion

  // #region Helper Methods
  function setPrevPage(): void {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }

  function setNextPage(): void {
    if (currentPage === pages) return;
    setCurrentPage(currentPage + 1);
  }
  // #endregion

  // #region Handle Methods
  function handleDocumentLoad({ numPages }: pdfjs.PDFDocumentProxy): void {
    setCurrentPage(InitialPage);
    setPages(numPages);
  }

  function handlePageLoad(page: pdfjs.PDFPageProxy): void {
    const pageViewport = page.getViewport({ scale: ViewerScale });
    const height = pageViewport.height;
    const width = pageViewport.width;
    setAspectRatio(width / height);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
    if (e.key === KeyboardKey.ArrowRight) setNextPage();
    else if (e.key === KeyboardKey.ArrowLeft) setPrevPage();
  }
  // #endregion

  // #region Render Methods
  function renderError(): JSX.Element {
    const errorId = `${componentId}ErrorPlaceholder`;
    const errorDataId = `${componentDataId}ErrorPlaceholder`;

    return (
      <PlaceholderContent
        className="slide-viewer_error-placeholder"
        theme={PlaceholderContentTheme.Light}
        id={errorId}
        data-id={errorDataId}
        {...placeholderProps}
      />
    );
  }

  function renderSpinner(): JSX.Element {
    return <Spinner masked={false} />;
  }

  function renderNoData(): JSX.Element {
    return failed ? renderError() : renderSpinner();
  }
  // #endregion

  return (
    <div className="slide-viewer" tabIndex={-1} ref={containerRef} onKeyDown={handleKeyDown} id={componentId} data-id={componentDataId}>
      <Document file={file} onLoadSuccess={handleDocumentLoad} error={renderError()} loading={renderSpinner()} noData={renderNoData()}>
        <Page pageNumber={currentPage} height={height} width={width} loading={renderSpinner()} onLoadSuccess={handlePageLoad} scale={ViewerScale} />
        <div className="slide-viewer_controls">
          <Ghostable ghosted={currentPage === 1}>
            <Button
              className="slide-viewer_control slide-viewer_control--left"
              icon="q4i-caret-left_4pt"
              theme={ButtonTheme.Transparent}
              onClick={setPrevPage}
              id={`${componentId}ControlLeft`}
              data-id={`${componentDataId}ControlLeft`}
            />
          </Ghostable>
          <Ghostable ghosted={currentPage === pages}>
            <Button
              className="slide-viewer_control slide-viewer_control--right"
              icon="q4i-caret-right_4pt"
              theme={ButtonTheme.Transparent}
              onClick={setNextPage}
              id={`${componentId}ControlRight`}
              data-id={`${componentDataId}ControlRight`}
            />
          </Ghostable>
        </div>
      </Document>
    </div>
  );
};

export default SlideViewer;
