// #region Imports
import "./eventShareModal.component.scss";
import React, { memo, RefObject, useRef } from "react";
import { Button, ButtonSize, ButtonTheme, Form, Modal, Textbox, isEmpty, NotificationService } from "@q4/nimbus-ui";
import { EventShareModalProps, EventUrl } from "./eventShareModal.definition";
// #endregion

function EventModal(props: EventShareModalProps): JSX.Element {
  const { pageId, visible, event, onCloseRequest } = props;
  const { id, previewToken } = event;

  const componentId = "EventShareModal";
  const viewerUrl = `${window.location.origin}/viewer/${id}`;
  const notificationService = useRef(new NotificationService());
  const inputRefs = {
    [EventUrl.Live]: useRef(),
    [EventUrl.Preview]: useRef(),
  };

  function handleCopy(urlType: EventUrl, inputRef: RefObject<HTMLInputElement>): void {
    notificationService.current.dismiss();

    if (isEmpty(urlType || inputRef?.current)) {
      notificationService.current.error("Oops! Something went wrong when trying to copy the URL to your clipboard.");
      return;
    }

    inputRef.current.select();
    document.execCommand("copy");
    notificationService.current.success(`The ${urlType} URL has been copied to your clipboard.`);
  }
  const handleCopyClick = (urlType: EventUrl, inputRef: RefObject<HTMLInputElement>) => (): void => handleCopy(urlType, inputRef);

  return (
    <Modal id={`${pageId}${componentId}`} className="event-share-modal" visible={visible} title="Share" onCloseRequest={onCloseRequest}>
      <Form
        id="EventModalForm"
        className="event-modal_form"
        fields={[
          {
            key: "live",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: (
              <span className="event-share-modal_label">
                Live URL
                <span>Locked until 15 minutes before the event starts</span>
              </span>
            ),
            labelHtmlFor: `${componentId}${EventUrl.Live}UrlInput`,
            children: (
              <div className="event-share-modal_field">
                <Textbox
                  id={`${componentId}${EventUrl.Live}Url`}
                  className="event-share-modal_input"
                  inputRef={inputRefs[EventUrl.Live]}
                  readOnly
                  value={viewerUrl}
                />
                <Button
                  id={`${componentId}${EventUrl.Live}UrlCopyButton`}
                  className="event-share-modal_button"
                  theme={ButtonTheme.Citrus}
                  size={ButtonSize.Large}
                  label="Copy"
                  onClick={handleCopyClick(EventUrl.Live, inputRefs[EventUrl.Live])}
                />
              </div>
            ),
          },
          {
            key: "preview",
            width: "1-of-1",
            smallWidth: "1-of-1",
            margin: false,
            label: (
              <span className="event-share-modal_label">
                Preview URL
                <span>Available to anyone who has the link</span>
              </span>
            ),
            labelHtmlFor: `${componentId}${EventUrl.Preview}UrlInput`,
            children: (
              <div className="event-share-modal_field">
                <Textbox
                  id={`${componentId}${EventUrl.Preview}Url`}
                  className="event-share-modal_input"
                  inputRef={inputRefs[EventUrl.Preview]}
                  readOnly
                  value={`${viewerUrl}?token=${previewToken}`}
                />
                <Button
                  id={`${componentId}${EventUrl.Preview}UrlCopyButton`}
                  className="event-share-modal_button"
                  theme={ButtonTheme.Citrus}
                  size={ButtonSize.Large}
                  label="Copy"
                  onClick={handleCopyClick(EventUrl.Preview, inputRefs[EventUrl.Preview])}
                />
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
}

EventModal.defaultProps = {
  edit: false,
};

export default memo(EventModal);
