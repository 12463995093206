import DocumentImage from "../../assets/images/documents.png";
import { PlaceholderContentProps } from "@q4/nimbus-ui";

export class SlideViewerPlaceholderProps {
  image = DocumentImage;
  title = "Oops! We encountered an issue";
  subtitle = "Please try reloading the presentation";

  constructor(placeholderContentProps: Partial<Pick<PlaceholderContentProps, "image" | "title" | "subtitle" | "actions">>) {
    Object.assign(this, placeholderContentProps);
  }
}

export class SlideViewerProps {
  dataId?: string;
  failed? = false;
  file: Blob;
  id?: string;
  placeholderProps?: SlideViewerPlaceholderProps;
}
