import React, { ComponentType, ElementType, memo } from "react";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute/privateRoute.component";
import Header from "./header/header.component";
import { ToastContainer } from "@q4/nimbus-ui";
import { Admin, Auth, ClientList, ClientDetails, EventDetails, Login, Logout, Unauthorised, Viewer } from "../pages.index";

const Root = (): JSX.Element => {
  const clientPath = "/clients";
  const viewerPath = "/viewer";
  const getAdminComponent = (page: ElementType): ComponentType => (): JSX.Element => <Admin header={Header} page={page} />;

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path={viewerPath} component={Viewer} />
          <Route exact path={`${viewerPath}/:eventId`} component={Viewer} />
          <Route exact path="/login" component={getAdminComponent(Login)} />
          <Route exact path="/logout" component={getAdminComponent(Logout)} />
          <Route exact path="/unauthorised" component={getAdminComponent(Unauthorised)} />
          <Route exact path="/token" component={getAdminComponent(Auth)} />
          <PrivateRoute exact path={clientPath} component={getAdminComponent(ClientList)} />
          <PrivateRoute exact path={`${clientPath}/:clientId/events`} component={getAdminComponent(ClientDetails)} />
          <PrivateRoute exact path={`${clientPath}/:clientId/events/:eventId/edit`} component={getAdminComponent(EventDetails)} />
          <Redirect exact from="*" to={clientPath} />
          <Redirect exact from="/" to={clientPath} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

export default memo(Root);
