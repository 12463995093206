export enum ContentType {
  FormData = "multipart/form-data",
  Json = "application/json",
  Pdf = "application/pdf",
}

export class ApiResponse<T> {
  data?: T;
  errors?: string[];
  success: boolean;
  totalCount?: number;

  constructor(success: boolean, data?: T, ...errors: string[]) {
    this.data = data;
    this.success = success;
    this.errors = errors;
  }
}

export enum ApiMethod {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export enum ResponseMessage {
  Ok = "Ok",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
  NotFound = "Not Found",
  TooManyRequests = "Too Many Requests",
  InternalServerError = "Internal Server Error",
  GatewayTimeout = "Gateway Timeout",
}

export enum ResponseCode {
  Ok = 200,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
  GatewayTimeout = 504,
}

export const ResponseCodeKey: Record<number, ResponseMessage> = {
  200: ResponseMessage.Ok,
  401: ResponseMessage.Unauthorized,
  403: ResponseMessage.Forbidden,
  404: ResponseMessage.NotFound,
  429: ResponseMessage.TooManyRequests,
  500: ResponseMessage.InternalServerError,
  504: ResponseMessage.GatewayTimeout,
};
