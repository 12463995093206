import { Event } from "../../../../service/event/event.model";
import { Moment } from "moment";

export interface EventModalProps {
  pageId: string;
  visible: boolean;
  event?: Event;
  onSave: Function;
  onCloseRequest: Function;
}

export interface EventModalEventState extends Event {
  startDay: Moment;
  startTime: string;
  isDST: boolean;
}

export enum EventModalDateFormat {
  StartDay = "MM/DD/YYYY",
  StartTime = "h:mma",
}

export type EventModalErrorProps = Pick<EventModalEventState, EventModalErrorKey>;

export enum EventModalErrorKey {
  Name = "name",
  StartDay = "startDay",
  StartTime = "startTime",
}
